.contact-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 65vh;
}

.input-wrapper {
	margin-bottom: 15px;
}
