.header {
	min-height: 25vh;
	display: flex;
	gap: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.navigation {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	padding: 15px 0;
	width: 80%;
	// width: 100%;
	display: flex;
	justify-content: center;
}

.header__navigation {
	display: flex;
	gap: 60px;
}

.navigation__item,
.navigation__item a {
	color: #000;
	list-style: none;
	text-decoration: none;
	font-size: 17px;
}
