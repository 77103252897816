.login {
	height: 100%;
}

.login-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	height: 100%;
}

.login_inputs-wrapper {
	display: flex;
	gap: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login-input {
	padding: 8px;
	border: 1px solid lightgray;
	border-radius: 4px;
	outline: none;
	width: 240px;
}
