.dashboard_form {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 500px;
	margin: 0 auto;
}

.dashboard_inputs-wrapper {
	display: flex;
	flex-direction: column;
	gap: 25px;
}
