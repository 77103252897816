.post {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	// text-align: center;
	margin-top: 30px;
}

.poster_wrapper {
	max-width: 100%;
}

.poster {
	max-height: 500px;
}



.blog_post {
	width: 50%;
	margin: 50px 0;
	// margin: 40px auto 0 auto;
}
